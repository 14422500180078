import React from 'react';
import ComingSoonSVG from "../../assets/coming-soon-ai.svg"
import "./coming-soon.css";

const ComingSoon = () => {
    return (
        <div class="container">
            <ComingSoonSVG></ComingSoonSVG>
        </div>
    )
};
export default ComingSoon;